function router(path){
  let route = null
  const pathParams = path.split( '/' )
  // Dynamically Load Modules
  switch(pathParams[1]) {
      // Fallback Page
      case 'lt':
      case 'en':
      case '':
        // Import the module
        route = 'base.js'
  }

  if (route) {
    import('./routes/' + route).then(module => {
      const newPage = new module.default();
      newPage.init();
    });
  }
};

window.addEventListener('load', () => {
  router(window.location.pathname);
})

module.exports = {
  init: router
}
